import axios from '@/plugins/axios';

class NumberService {
  getActiveNumbers(data) {
    this.data = data;
    return axios().get('numbers', { params: this.data }).then((response) => response.data);
  }
}

export default new NumberService();
