<template>
  <div class="form-group">
    <div class="row">
      <div class="col">
        <label for="sms-body">{{$t('sms.send-msg.sms-input-component.title')}}</label> <!-- Conteúdo -->
      </div>
      <div class="col text-right">
        <div class="popup-box-container dropdown" v-if="variables.length">
          <a href="javascript:void(0)" v-dropdown>
            <span class="badge badge-primary">{{$t('sms.send-msg.sms-input-component.lbl-variables')}}</span> <!-- Variaveis -->
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            x-placement="bottom-end"
          >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              v-for="variable in variables"
              :key="variable.value"
              @click="addVariable(variable)"
              >{{ variable.name }}</a
            >
          </div>
        </div>
        <Emojis @select="addEmoji" title="Emoji" />
      </div>
    </div>
    <textarea
      class="form-control"
      rows="6"
      maxlength="612"
      id="sms-body"
      :value="value"
      @input="setValue($event.target.value)"
    ></textarea>
    <div class="textarea-information bg-light text-right">
      <small v-if="unicode" class="form-text text-danger pull-left"
        >{{$t('sms.send-msg.sms-input-component.lbl-special-chars')}}</small><!-- Caracteres Especiais usados -->
      <small class="form-text text-muted">
        <b>
          <span v-if="hasVar">Aprox.</span>
          {{ segments }}
        </b>
        SMS, <b>{{ length }}</b> {{$t('sms.send-msg.sms-input-component.lbl-chars')}}.
      </small>
    </div>
  </div>
</template>

<script>
import Emojis from '@/components/Emojis.vue';

const GSM = /^[ -~\n]*$/;

export default {
  name: 'SmsInput',
  components: {
    Emojis,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    normalize: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      segments: 1,
      length: 0,
      hasVar: false,
      unicode: false,
    };
  },
  watch: {
    value() {
      this.countSegments();
    },
    normalize() {
      this.countSegments();
    },
  },
  methods: {
    setValue(value) {
      this.$emit('input', value);
    },
    countSegments() {
      this.length = this.value.length;

      if (this.value.match(GSM) || this.normalize) {
        this.unicode = false;
      } else {
        this.unicode = true;
      }

      if (/{(.*?)}/.test(this.value)) {
        this.hasVar = true;
      } else {
        this.hasVar = false;
      }

      if (this.unicode) {
        this.segments = this.length <= 70 ? 1 : Math.ceil(this.length / 67);
      } else {
        this.segments = this.length <= 160 ? 1 : Math.ceil(this.length / 153);
      }
    },
    addVariable(variable) {
      this.setValue(`${this.value}{${variable.value}}`);
    },
    addEmoji(emoji) {
      this.setValue(`${this.value}${emoji}`);
    },
  },
};
</script>

<style lang="scss">
.popup-box-container {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}
textarea:not(:last-child) {
  border-bottom: none !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  /* border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; */
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
} */
</style>
