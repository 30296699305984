<template>
  <div class="content">
    <PageHeader :title="$t('sms.send-msg.header')" />
    <div class="page-content container-fluid">
      <div class="card card-tabs">
        <div class="card-header">
          <ul class="nav nav-tabs primary-tabs">
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link active"
                v-tab="'simple'"
                data-toggle="tab"
                aria-expanded="true"
                @click="changeType('simple')"
              >
                {{$t('sms.send-msg.tabs.simple.title')}} <!-- Simples -->
              </a>
            </li>
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link"
                v-tab="'contact'"
                aria-expanded="true"
                @click="changeType('contacts')"
                >{{$tc('sms.send-msg.tabs.contact.title', 1)}}</a
              > <!-- Contato -->
            </li>
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link"
                v-tab="'group'"
                aria-expanded="true"
                @click="changeType('groups')"
              >
                {{$t('sms.send-msg.tabs.groups.title')}} <!-- Grupos -->
              </a>
            </li>
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link"
                v-tab="'files'"
                aria-expanded="true"
                @click="changeType('files')"
              >
                {{$t('sms.send-msg.tabs.archives.title')}} <!-- Arquivos -->
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <form action="#" class="form-horizontal">
            <div class="form-body">
              <div class="row">
                <div class="col-lg-8 col-md-12 col-sm-12">
                  <div v-if="shortCodes.length >= 1" class="form-group row">
                    <label class="control-label text-left col-3">
                      {{$t('sms.send-msg.lbl-sender')}} <!-- Remetente -->
                    </label>
                    <div class="col-md-6">
                      <select-input
                        label="name"
                        :reduce="(shortCode) => shortCode.id"
                        v-model="form.shortCodeId"
                        :options="shortCodes"
                        :clearable="false"
                        v-on:option:selected="changeProduct"
                      />
                    </div>
                  </div>
                  <!--<div class="form-group row">
                    <label class="control-label text-left col-3">
                      Produto
                    </label>
                    <div class="col-md-6">
                      <select-input
                        label="name"
                        :reduce="(product) => product.id"
                        v-model="form.productId"
                        :options="products"
                        :clearable="false"
                        v-on:option:selected="changeProduct"
                      />
                    </div>
                  </div>-->
                  <div v-if="form.type != 'simple'" class="form-group row">
                    <label class="control-label text-left col-3">
                      {{$t('sms.send-msg.lbl-campaign')}} <!-- Nome da Campanha -->
                    </label>
                    <div class="col-md-9">
                      <input v-model="form.name" class="form-control" />
                    </div>
                  </div>
                  <div class="tab-content">
                    <div class="tab-pane active" id="simple">
                      <div class="form-group row">
                        <label class="control-label text-left col-12"
                          >{{$t('sms.send-msg.tabs.simple.lbl-recipient')}}</label
                        > <!-- Destinatários -->
                        <div class="col-md-12">
                          <textarea
                            v-model="form.to"
                            rows="6"
                            class="form-control"
                            v-bind:class="{
                              invalid:
                                submited &&
                                form.to.length == 0 &&
                                form.type == 'simple',
                            }"
                            :placeholder="this.$t('sms.send-msg.tabs.simple.lbl-example')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="contact">
                      <div class="form-group row">
                        <label class="control-label text-left col-4"
                          >{{$tc('sms.send-msg.tabs.contact.title', 2)}}</label
                        > <!-- Contatos -->
                        <div class="col-md-12 block-el">
                          <div>
                            <button
                              class="btn btn-success"
                              type="button"
                              v-modal="{ target: 'contact-send-modal' }"
                            >
                              {{$t('sms.send-msg.tabs.contact.lbl-add-contact')}} <!-- Adicionar Contato -->
                            </button>
                            <hr />
                            <b-collapse id="collapse-1" visible class="mt-2">
                              <b-card class="send-group-card">
                                <div
                                  class="text-center table-placeholder"
                                  v-if="contacts.length == 0"
                                >
                                  <i class="fas fa-user font-size-80"></i>
                                  <h5 class="card-title m-t-20">
                                    {{$t('sms.send-msg.tabs.contact.lbl-none-contact')}} <!-- Nenhum contato adicionado -->
                                  </h5>
                                </div>
                                <p class="card-text" v-if="contacts.length > 0">
                                  {{$t('sms.send-msg.tabs.contact.lbl-added-contacts')}} ({{ contacts.length }})
                                </p> <!-- Contatos adicionados -->
                                <ul
                                  class="ul-list-groups"
                                  v-if="contacts.length > 0"
                                >
                                  <li
                                    v-for="(contact, i) in contacts"
                                    :key="contact.id"
                                  >
                                    <div
                                      class="
                                        alert
                                        alert-secondary
                                        alert-outline
                                        alert-dismissible
                                        fade
                                        show
                                      "
                                      role="alert"
                                    >
                                      {{ contact.first_name }} /
                                      {{ contact.mobile_number }}
                                      <button
                                        type="button"
                                        class="close"
                                        @click="removeContact(i)"
                                      >
                                        <span
                                          aria-hidden="true"
                                          class="fas fa-times"
                                        ></span>
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </b-card>
                            </b-collapse>
                          </div>
                          <!-- <group-table isSendPage="true" />-->
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="group">
                      <div class="form-group row">
                        <label class="control-label text-left col-12"
                          >{{$t('sms.send-msg.tabs.groups.title')}}</label
                        > <!-- Grupos -->
                        <div class="col-md-12 block-el">
                          <div>
                            <button
                              class="btn btn-success"
                              type="button"
                              v-modal="{ target: 'group-send-modal' }"
                            >
                              {{$t('sms.send-msg.tabs.groups.lbl-add-group')}} <!-- Adicionar Grupo -->
                            </button>
                            <hr />
                            <b-collapse id="collapse-1" visible class="mt-2">
                              <b-card class="send-group-card">
                                <div
                                  class="text-center table-placeholder"
                                  v-if="groups.length == 0"
                                >
                                  <i class="fas fa-users font-size-80"></i>
                                  <h5 class="card-title m-t-20">
                                    {{$t('sms.send-msg.tabs.groups.lbl-none-group')}} <!-- Nenhum grupo adicionado -->
                                  </h5>
                                </div>
                                <p class="card-text" v-if="groups.length > 0">
                                  {{$t('sms.send-msg.tabs.groups.lbl-added-groups')}} ({{ groups.length }}) <!-- Grupos adicionados -->
                                </p>
                                <ul
                                  class="ul-list-groups"
                                  v-if="groups.length > 0"
                                >
                                  <li
                                    v-for="(group, i) in groups"
                                    :key="group.id"
                                  >
                                    <div
                                      class="
                                        alert
                                        alert-secondary
                                        alert-outline
                                        alert-dismissible
                                        fade
                                        show
                                      "
                                      role="alert"
                                    >
                                      {{ group.name }}
                                      <button
                                        type="button"
                                        class="close"
                                        @click="removeGroup(i)"
                                      >
                                        <span
                                          aria-hidden="true"
                                          class="fas fa-times"
                                        ></span>
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </b-card>
                            </b-collapse>
                          </div>
                          <!-- <group-table isSendPage="true" />-->
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="files">
                      <div class="form-group row">
                        <label class="control-label text-left col-3"
                          >{{$t('sms.send-msg.tabs.archives.lbl-add-archive')}}</label
                        > <!-- Adicionar Arquivo -->
                        <div v-if="!importing" class="col-md-6">
                          <input
                            ref="uploadedFile"
                            type="file"
                            accept=".csv"
                            hidden
                            @change="uploadFile"
                          />
                          <div class="input-group mb-3">
                            <div class="form-control">{{ form.file }}</div>
                            <div class="input-group-append">
                              <button
                                class="btn btn-primary"
                                type="button"
                                @click="openFileUpload"
                              >
                                <template v-if="form.file"
                                  >{{$t('sms.send-msg.tabs.archives.lbl-change-archive')}}</template
                                > <!-- Trocar Arquivo -->
                                <template v-else>{{$t('sms.send-msg.tabs.archives.lbl-select-archive')}}</template> <!-- Selecionar Arquivo -->
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-else class="col-md-6">
                          <div class="row">
                            <div class="col-12">
                              <small>{{$t('sms.send-msg.tabs.archives.lbl-validating')}}</small> <!-- O arquivo está sendo validado -->
                            </div>
                          </div>
                          <div class="progress">
                            <div
                              class="
                                progress-bar
                                progress-bar-striped
                                progress-bar-animated
                              "
                              role="progressbar"
                              :style="`width: ${uploadProgress}%;`"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {{ uploadProgress }}%
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <!-- Tooltip: Layout do arquivo para importação. -->
                          <a
                            href="/exemplos/example.csv"
                            class="btn btn-bulk btn-success"
                            v-tooltip.top="$t('sms.send-msg.tabs.archives.lbl-tooltip-archive')"
                            >{{$t('generic-str.example')}}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <sms-input
                    v-model="form.body"
                    :normalize="form.normalize"
                    :variables="vars"
                  />
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12">
                  <!--<div v-if="selectedProduct && selectedProduct.plan.credits" class="card">
                    <div class="card-body">
                      <div
                        class="icon-rounded icon-rounded-info float-left m-r-20"
                      >
                        <i class="la la-paper-plane f-w-600"></i>
                      </div>
                      <h5 class="card-title m-b-5">
                        {{ selectedProduct.plan.credits }}
                      </h5>
                      <h6 class="text-muted m-t-10">Creditos</h6>
                    </div>
                  </div>-->
                  <div class="card card-border-info">
                    <div class="card-header p-l-20 p-b-15 p-t-15">{{$t('options-div.title')}}</div>
                    <div class="card-body">
                      <div class="form-group row">
                        <div class="col-md-12">
                          <div
                            class="
                              custom-control
                              custom-checkbox
                              custom-control-inline
                              custom-control-inline-send
                            "
                          >
                            <input
                              class="tgl tgl-light tgl-primary"
                              id="normalized"
                              type="checkbox"
                              v-model="form.normalize"
                              name="normalized"
                              @change="countSegments"
                            />
                            <label class="tgl-btn" for="normalized"></label>
                            <label class="label-send-check"
                              >{{$t('options-div.lbl-replace-chars')}}</label> <!-- Substituir Caracteres Especiais -->
                          </div>
                        </div>
                      </div>
                      <!--<div class="form-group row">
                        <div class="col-md-12">
                          <div
                            class="custom-control custom-checkbox custom-control-inline custom-control-inline-send"
                          >
                            <input
                              class="tgl tgl-light tgl-primary"
                              id="flash"
                              type="checkbox"
                              v-model="form.flash"
                              name="flash"
                              :disabled="unicode"
                            />
                            <label class="tgl-btn" for="flash"></label>
                            <label class="label-send-check">Enviar como flash</label>
                            <span
                              class="h5"
                              v-tooltip.top="'A mensagem será exibida no telefone imediatamente sem salvar nele. Sujeito a disponibilidade da operadora.'"
                            >
                              <i class="la la-question-circle"></i>
                            </span>
                          </div>
                        </div>
                      </div>-->
                      <div class="form-group row">
                        <div class="col-md-12">
                          <div
                            class="
                              custom-control
                              custom-checkbox
                              custom-control-inline
                              custom-control-inline-send
                            "
                          >
                            <input
                              class="tgl tgl-light tgl-primary calendar-change"
                              id="schedule"
                              type="checkbox"
                              v-model="form.schedule"
                              name="schedule"
                              @change="changeSchedule"
                            />
                            <label class="tgl-btn" for="schedule"></label>
                            <label class="label-send-check">{{$t('options-div.lbl-schedule')}}</label><!-- Agendar -->
                          </div>
                          <date-picker
                            v-if="form.schedule"
                            v-model="form.datetime"
                            :config="options"
                            class="form-control input-date"
                            :placeholder="$t('generic-str.date-hour')"
                          ></date-picker>
                        </div>
                      </div>
                      <hr class="dashed" />
                      <template-input
                        v-model="form.template"
                        @input="insertTemplate"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer bg-light">
          <button
            @click="send"
            type="button"
            :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
            :disabled="isSending || importing"
            class="btn btn-primary float-right"
          >
            {{$t('generic-str.send')}}
          </button> <!-- Enviar -->
        </div>
      </div>
    </div>
    <create-contact-modal id="create-contact"></create-contact-modal>
    <create-group-modal id="create-group"></create-group-modal>
    <group-send-modal id="group-send-modal"></group-send-modal>
    <contact-send-modal id="contact-send-modal"></contact-send-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from '@/plugins/axios';
import SmsService from '@/services/sms.service';
import NumberService from '@/services/number.service';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import Utils from '@/models/utils';
import CreateContactModal from '@/components/contacts/CreateContactModal.vue';
import GroupSendModal from '@/components/groups/GroupSendModal.vue';
import ContactSendModal from '@/components/contacts/ContactSendModal.vue';
import CreateGroupModal from '@/components/groups/CreateGroupModal.vue';
import SelectInput from '@/components/SelectInput.vue';
import TemplateInput from '@/components/sms/TemplateInput.vue';
import SmsInput from '@/components/form/SmsInput.vue';
import Swal from 'sweetalert2';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import $ from 'jquery';
import moment from 'moment';

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

const GSM = /^[ -~\n]*$/;

export default {
  name: 'SendSms',
  components: {
    PageHeader,
    TemplateInput,
    CreateContactModal,
    ContactSendModal,
    GroupSendModal,
    CreateGroupModal,
    datePicker,
    SelectInput,
    SmsInput,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      numbers: [],
      addVars: false,
      hasVar: false,
      groups: [],
      isSending: false,
      contacts: [],
      files: [],
      selectedGroups: [],
      selectedContacts: [],
      selectedFiles: [],
      importing: false,
      uploadProgress: 0,
      vars: [],
      fileVariables: [],
      shortCodes: [],
      selectedProduct: [],
      form: {
        type: 'simple',
        productId: '',
        shortCodeId: '',
        to: '',
        body: '',
        file: '',
        normalize: false,
        flash: false,
        template: null,
      },
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: true,
        minDate: moment(),
        locale: 'pt',
        keepInvalid: true,
      },
      unicode: false,
      replace: false,
      flash: false,
      segments: 1,
      submited: false,
      length: 0,
    };
  },
  computed: {
    classes() {
      return ['wizard'];
    },
  },
  created() {
    this.fetchShortCodes();
    // this.fetchProducts();
  },
  methods: {
    fetchShortCodes() {
      SmsService.getAccountShortCodes().then(
        (shortCodes) => {
          this.shortCodes = shortCodes;
          if (shortCodes) {
            this.form.shortCodeId = shortCodes[0].id;
          }
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeProduct(product) {
      this.selectedProduct = product;
    },
    selectedGroupsChange(param) {
      param.selectedGroups.forEach((group) => {
        if (!this.selectedGroups.includes(group.id)) {
          this.groups.push(group);
          this.selectedGroups.push(group.id);
        }
      });
    },
    selectedContactsChange(param) {
      param.selectedContacts.forEach((contact) => {
        if (!this.selectedContacts.includes(contact.id)) {
          this.contacts.push(contact);
          this.selectedContacts.push(contact.id);
        }
      });
    },
    selectedFilesChange(param) {
      param.selectedFiles.forEach((file) => {
        if (!this.selectedFiles.includes(file.id)) {
          this.files.push(file);
          this.selectedFiles.push(file.id);
        }
      });
    },
    changeType(type) {
      if (type === 'contacts' || type === 'groups') {
        this.vars = [
          { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' }, // Primeiro Nome
          { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' }, // Sobrenome
          { name: this.$t('generic-str.lbl-cellphone'), value: 'mobile_number' }, // Celular
          { name: 'Email', value: 'email' },
        ];
      } else if (type === 'files') {
        this.vars = this.fileVariables;
      } else {
        this.vars = [];
      }
      this.form.type = type;
      this.submited = false;
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    uploadFile(event) {
      const self = this;
      const reader = new FileReader();
      this.importing = true;
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append('file', file);
      this.form.file = file.name;

      reader.onload = () => {
        self.fileVariables = [];
        const variables = reader.result
          .split('\n')
          .shift()
          .replace('\r', '')
          .split(';'); // first line
        variables.forEach((variable) => {
          self.fileVariables.push({
            value: variable,
            name: variable,
          });
        });
        self.vars = self.fileVariables;
      };
      reader.readAsText(file, 'UTF-8');

      const config = {
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
      };

      axios()
        .post('files', formData, config)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            self.form.file = '';
            self.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.importing = false;
          this.uploadProgress = 0;
        });
    },
    removeGroup(i) {
      this.groups.splice(i, 1);
      this.selectedGroups.splice(i, 1);
      this.$root.$emit('groups.selected.refresh', {
        selectedGroups: this.groups,
      });
    },
    removeContact(i) {
      this.contacts.splice(i, 1);
      this.selectedContacts.splice(i, 1);
      this.$root.$emit('contacts.selected.refresh', {
        selectedContacts: this.contacts,
      });
    },
    removeFile(i) {
      this.files.splice(i, 1);
      this.selectedFiles.splice(i, 1);
    },
    addVar(entity) {
      this.addVars = false;
      this.form.body = this.form.body.concat(` {${entity}} `);
      this.hasVar = true;
      this.countSegments(15);
    },
    smsTextChange() {
      let contVar = false;
      if (this.type === 'files') {
        this.fileVariables.forEach((element) => {
          if (this.form.body.includes(element.value)) {
            contVar = true;
          }
          this.hasVar = contVar;
        });
      } else {
        this.vars.forEach((element) => {
          if (this.form.body.includes(element.value)) {
            contVar = true;
          }
          this.hasVar = contVar;
        });
      }
    },
    showAddVar() {
      this.addVars = true;
    },
    hideAddVar() {
      this.addVars = false;
    },
    changeSchedule() {},
    countSegments(newValues) {
      this.smsTextChange();
      const newValue = Utils.isNumber(newValues) ? newValues : 0;
      // this.segments =
      this.length = this.form.body.length + newValue;

      if (this.form.body.match(GSM) || this.form.normalize) {
        this.unicode = false;
      } else this.unicode = true;

      if (this.unicode) {
        this.form.flash = null;
        this.segments = this.length <= 70 ? 1 : Math.ceil(this.length / 67);
      } else {
        this.segments = this.length <= 160 ? 1 : Math.ceil(this.length / 153);
      }
    },
    insertTemplate(template) {
      this.form.body = template;
      this.countSegments();
      // form.body = e.target.value;
    },
    send() {
      this.submited = true;
      if (this.form.datetime) {
        const scheduledAt = moment(
          this.form.datetime,
          'DD/MM/YYYY HH:mm:ss',
        ).format('YYYY-MM-DD HH:mm:ss');
        this.form.scheduled_at = scheduledAt;
      }
      switch (this.form.type) {
        case 'simple':
          this.form.contacts = [];
          this.form.groups = [];

          if (this.form.to.length > 0 && this.form.body.length > 0) {
            this.sendRequest();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'error',
              title: this.$t('warnings.send-component.missing'),
            });
          }
          break;
        case 'contacts':
          if (this.selectedContacts.length > 0 && this.form.body.length > 0) {
            this.form.contacts = this.selectedContacts;
            this.form.to = '';

            this.sendRequest();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'error',
              title: this.$t('warnings.send-component.contact'),
            });
          }
          break;
        case 'groups':
          this.form.contacts = [];
          this.form.to = '';

          if (this.selectedGroups.length > 0 && this.form.body.length > 0) {
            this.form.groups = this.selectedGroups;
            this.sendRequest();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'error',
              title: this.$t('warnings.send-component.group'),
            });
          }
          break;
        case 'files':
          if (this.form.file && this.form.body.length > 0) {
            this.form.files = this.selectedFiles;
            this.sendRequest();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'error',
              title: this.$t('warnings.send-component.archive'),
            });
          }
          break;
        default:
      }
    },
    sendRequest() {
      Swal.fire({
        title: 'Enviar Mensagens',
        text: this.$t('warnings.send-component.send-msg-confirm'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.isSending = true;
          console.log(JSON.stringify(this.form));
          SmsService.sendSms(this.form)
            .then(
              (send) => {
                if (this.form.type === 'simple') {
                  this.$router.push({
                    name: 'SmsSuccess',
                    params: { sms: send.data },
                  });
                } else {
                  this.$router.push('/sms/campaigns');
                }
                this.$toast.show({
                  title: this.$t('generic-str.success'),
                  content: this.$t('warnings.send-component.msg-sent'),
                  type: 'success',
                });
                this.isSending = false;
              },
              (error) => {
                this.$toast.show({
                  title: this.$t('sms.infos.filters.status.lbl-error'),
                  content: error.response.data.message,
                  type: 'danger',
                });
                this.isSending = false;
              },
            )
            .catch((error) => {
              console.log(error.response);
              this.isSending = false;
            });
        }
      });
    },
    putEmoji(emoji) {
      if (this.form.body.length) {
        this.form.body += ` ${emoji}`;
      } else {
        this.form.body = emoji;
      }
      this.countSegments();
    },
  },
  mounted() {
    this.$root.$on('groups.addonsend', this.selectedGroupsChange);
    this.$root.$on('contacts.addonsend', this.selectedContactsChange);
    this.$root.$on('files.addonsend', this.selectedFilesChange);
  },
};
</script>

<style lang="scss">
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}
.input-date {
  margin-top: 15px !important;
}
.form-control.invalid {
  border-color: #ff5c75;
}
.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #a6a6a6 !important;
}
.send-group-card {
  box-shadow: none;
}
.send-group-card .table-placeholder i,
h5 {
  color: #b5b5b5;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.custom-control-inline-send {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.label-send-check {
  margin-left: 10px;
  margin-top: 2px;
}
.ul-list-groups {
  margin: 0px;
  padding: 0px;
}
.ul-list-groups li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
</style>
