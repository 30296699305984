<template>
  <div class="form-group row">
    <div class="col-8">
      <select class="form-control" :disabled="!loaded" @change="changeTemplate">
        <option value>{{$t('generic-str.lbl-choose-template')}}</option><!-- Escolher Template -->
        <option
          v-for="template in templates"
          :key="template.id"
          :value="template.body"
        >{{ template.name }}</option>
      </select>
      <div v-if="!loaded" class="preloader pl-xxs pls-primary">
        <svg class="pl-circular" viewBox="25 25 50 50">
          <circle class="plc-path" cx="50" cy="50" r="20" />
        </svg>
      </div>
    </div>
    <div class="col-4">
      <router-link to="templates" class="btn btn-success btn-icon" tag="button">+</router-link>
    </div>
  </div>
</template>

<script>
import SmsService from '@/services/sms.service';

export default {
  data() {
    return {
      loaded: false,
      templates: {},
    };
  },
  mounted() {
    this.fetchTemplates();
  },
  methods: {
    fetchTemplates() {
      SmsService.getTemplates({}).then(
        (response) => {
          this.loaded = true;
          this.templates = response.data;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeTemplate(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.card-body {
  min-height: 300px;
}
.preloader {
  position: absolute;
  top: 5px;
  right: 35px;
}
</style>
